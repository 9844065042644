<template>
  <div class="mt-3 p-2">
    <b-container fluid class="mb-2">
      <!-- Heading section -->
      <b-row class="mb-2">
        <b-col md="6">
          <h5 class="text-uppercase heading">Activities</h5>
          <p>List of the Activities in the Organization</p>
        </b-col>
        <b-col md="6" class="text-right">
        </b-col>
      </b-row>

      <!-- project, zone and plot level filter -->
      <b-row class="mb-2">
        <b-col md="4">
          <b-form-group id="input-group-1" label="" label-for="input-2">
            <select
              name=""
              id=""
              class="form-control"
              v-model="project_id"
              @change="projectChange"
            >
              <option value="">Select Project</option>
              <option
                :value="project._id"
                v-for="(project, index) in projects"
                :key="index"
              >
                {{ project.name }}
              </option>
            </select>
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group id="input-group-2" label="" label-for="input-2">
            <select
              name=""
              id=""
              class="form-control"
              v-model="zone_id"
              @change="zoneChange"
              :disabled="!project_id"
            >
              <option value="">Select Zone</option>
              <option
                :value="zone._id"
                v-for="(zone, index) in zones"
                :key="index"
              >
                {{ zone.code }}
              </option>
            </select>
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group id="input-group-2" label="" label-for="input-2">
            <select
              name=""
              id=""
              class="form-control"
              v-model="plot_id"
              :disabled="!zone_id"
            >
              <option value="">Select Plot</option>
              <option
                :value="plot._plot_id._id"
                v-for="(plot, index) in plots"
                :key="index"
              >
                {{ plot._plot_id.code }}
              </option>
            </select>
          </b-form-group>
        </b-col>
        <b-col md="2">
          <b-button
            variant="success"
            @click="fetchData"
            :disabled="!project_id"
            block
          >
            Search
          </b-button>
        </b-col>
      </b-row>

      <!-- Items per page and wild search section -->
      <b-row class="mb-3">
        <b-col md="2">
          <!-- Select User Per Page  -->
          <b-form-select v-model="item_per_page" @change="changeItemNumber">
            <b-form-select-option value="10">10</b-form-select-option>
            <b-form-select-option value="50">50</b-form-select-option>
            <b-form-select-option value="100">100</b-form-select-option>
          </b-form-select>
        </b-col>
        <b-col md="9">
          <!-- Filter -->
          <b-form-group label="" label-for="filter-input" class="mb-0">
            <b-input-group>
              <b-form-input
                id="filter-input"
                v-model="filter"
                type="search"
                placeholder="Type to Search"
              ></b-form-input>
              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''"
                  >Clear</b-button
                >
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="1" class="d-flex justify-content-end">
          <b-button variant="success" @click="downloadCSV">
            <i class="fa fa-download" aria-hidden="true"></i>
          </b-button>
        </b-col>
      </b-row>

      <b-table
        class="dataTable rounded-left"
        :outlined="true"
        :items="items"
        :fields="fields"
        :filter="filter"
        @filtered="filtered"
        :per-page="perPage"
        :current-page="currentPage"
        :sort-desc.sync="sortDesc"
        head-variant="none"
        table-variant="none"
        :busy="isBusy"
      >
        <template #cell(#)="data">
          <span v-if="currentPage == 1">{{ data.index + 1 }}</span>
          <span v-else>{{
            (currentPage - 1) * item_per_page + (data.index + 1)
          }}</span>
        </template>
        <template #cell(image)="row">
          <img
            :src="row.item.image"
            class="rounded"
            width="50px"
            height="50px"
          />
        </template>
        <template #cell(start_date)="row">
          {{ row.item.start_date | moment('DD-MM-YYYY') }}
        </template>
        <template #cell(end_date)="row">
          {{ row.item.end_date | moment('DD-MM-YYYY') }}
        </template>
        <template #cell(created_ts)="row">
          {{ row.item.created_ts | moment('DD-MM-YYYY') }}
        </template>
        <template #cell(progress_status)="row">
          <span v-if="row.item.progress_status == 1">Pending</span>
          <span v-else-if="row.item.progress_status == 2">In Progress</span>
          <span v-else-if="row.item.progress_status == 3">Completed</span>
        </template>

        <template #cell(actions)="row">
          <b-dropdown
            size="sm"
            id="dropdown-1"
            text="Actions"
            variant="primary"
          >
            <b-dropdown-item>
              <router-link
                :to="'/activities/' + row.item._id"
                style="text-decoration: none; color: #000"
              >
                View
              </router-link>
            </b-dropdown-item>

          </b-dropdown>
        </template>

        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>
      </b-table>
      <div class="d-flex justify-content-center mt-4">
        <b-pagination
          pills
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="my-table"
        ></b-pagination>
      </div>
    </b-container>
  </div>
</template>

<script>
import moment from 'moment';
export default {
  components: {
  },
  data() {
    return {
      item_per_page: 10,
      perPage: 10,
      currentPage: 1,
      sortDesc: false,
      filter: null,
      isBusy: false,
      items: [],
      downloadItems: [],
      fields: [
        '#',
        'image',
        {
          key: 'description',
          label: 'Description',
        },
        {
          key: 'code',
          label: 'Activity Code',
          sortable: true,
        },
        {
          key: '_activityTypeID.name',
          label: 'Activity Type',
          sortable: true,
        },
        {
          key: 'progress_status',
          label: 'Activity Status',
          sortable: true,
        },
        {
          key: 'progress',
          label: 'Activity Progress',
          sortable: true,
        },
        {
          key: 'start_date',
          label: 'Start Date',
          sortable: true,
        },
        {
          key: 'end_date',
          label: 'End Date',
          sortable: true,
        },
        {
          key: 'created_ts',
          label: 'Created Date',
          sortable: true,
        },
        'actions',
      ],
      datas: [],
      activitiesType: '',
      project_id: '',
      zone_id: '',
      plot_id: '',
      projects: [],
      zones: [],
      plots: [],
    };
  },
  mounted() {
    this.getProjects();
    this.fetchData();
    this.getActivityType();
  },
  computed: {
    rows() {
      return this.items.length;
    },
  },
  methods: {
    filtered(filteredItems) {
      this.downloadItems = filteredItems;
    },

    downloadCSV() {
      let csv =
        '\ufeff' +
        'DESCRIPTION,ACTIVITY CODE, ACTIVITY TYPE, ACTIVITY STATUS, ACTIVITY PROGRESS, START DATE, END DATE, CREATED DATE\n';
      this.downloadItems.forEach((el) => {
        let activityStatus = '';
        if (el['progress_status'] === 1) {
          activityStatus = 'Pending';
        } else if (el['progress_status'] === 2) {
          activityStatus = 'In Progress';
        } else {
          activityStatus = 'Completed';
        }

        var line =
          el['description'] +
          ',' +
          el['code'] +
          ',' +
          el['_activityTypeID'].name +
          ',' +
          activityStatus +
          ',' +
          el['progress'] +
          ',' +
          `${moment(el['start_date']).format('DD-MM-YYYY')}` +
          ',' +
          `${moment(el['end_date']).format('DD-MM-YYYY')}` +
          ',' +
          `${moment(el['created_ts']).format('DD-MM-YYYY')}` +
          '\n';
        csv += line;
      });
      var blob = new Blob([csv], { type: 'csv/plain' });
      let link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'Activities.csv';
      link.click();
    },

    getProjects() {
      window.axios.get('project').then((resp) => {
        this.projects = resp.data.data;
      });
    },
    getActivityType() {
      window.axios.get('activity-type').then((resp) => {
        console.log('Activity Type:' + resp);
        this.activitiesType = resp.data.data;
      });
    },
    deleteActivity(id) {
      let prompt = confirm('Are you sure want to delete ? ');
      if (prompt) {
        window.axios.delete('activity/' + id).then(() => {
          this.fetchData();
        });
      }
    },
    changeItemNumber() {
      this.perPage = this.item_per_page;
    },
    fetchData() {
      this.isBusy = true;
      window.axios
        .get('activity', {
          params: {
            project_id: this.project_id,
            zone_id: this.zone_id,
            plot_id: this.plot_id,
          },
        })
        .then((resp) => {
          console.log(resp);
          if (resp.data.success) {
            this.isBusy = false;
            this.datas = resp.data.data;
            this.items = resp.data.data;
            this.downloadItems = this.items;
          }
          setTimeout(() => (this.success = false), 3000);
        });
    },

    projectChange() {
      (this.zones = []), (this.plots = []);
      this.zone_id = '';
      this.plot_id = '';
      this.selectedProject = this.projects.filter((number) => {
        return number._id == this.project_id ? number.name : false;
      });
      window.axios.get('/project-zones/' + this.project_id).then((resp) => {
        this.zones = resp.data.data;
      });
    },

    zoneChange() {
      this.selectedZone = this.zones.filter((number) => {
        return number._id == this.zone_id ? number.code : false;
      });
      console.log('Zone ID');
      console.log(this.zone_id);
      window.axios.get('/all-plots-assigned/' + this.zone_id).then((resp) => {
        this.plots = resp.data.data;
        console.log('Plots');
        console.log(this.plots);
      });
    },
  },
};
</script>

<style scoped>
.heading {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 3.5%;
  margin-top: 5px;
  margin-bottom: 5px;
  color: #454545;
}
/deep/ .dataTable thead {
  background-color: #dcffd9;
  height: 64px;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 3.5%;
  font-family: 'Poppins', sans-serif;
  padding-top: 14px;
  padding-bottom: 14px;
  color: #303030;
}
/deep/ .dataTable {
  text-align: center;
  margin-bottom: 21.5px;
  margin-left: 2px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}
/deep/ .dataTable tr td {
  font-family: 'Poppins', sans-serif;
  font-size: 17px;
  letter-spacing: 3.5%;
  line-height: 25.5px;
  font-weight: 400;
  padding-top: 12px;
  padding-bottom: 12px;
}
</style>
